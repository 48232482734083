//import { useState, useRef } from "react";
import React, { useEffect, useState, Fragment } from "react";
import { ReactComponent as CloseMenu } from "../assets/x2.svg";
import { ReactComponent as MenuIcon } from "../assets/menu2.svg";
import { ReactComponent as Logo } from "../assets/logo.svg";
import "./Header_2.css";
import { ethers } from "ethers";
import axios from "axios";
import Web3 from 'web3';
import { id } from "ethers/lib/utils";
import Papa from "papaparse";
import {getProof} from "../merkle/merkleTree"
var testloop = 0;
var WhiteList;
const baseURL = "https://sv1.siri-art.com:5000/";
var isSignedIn = false;
var numWhitelist = 0;
var numNFTlist = 0;
var config = {
    headers: {
        'Content-Length': 0,
        'Content-Type': 'text/plain'
    },
    responseType: 'text'
};

export default function Header() {
    useEffect(() => {
    }, []);
    //const resultBox = useRef();
    //const [signatures, setSignatures] = useState([]);
    
    //alert(getProof("0x82EC40C7381ABa62948dDC28D716c6bc34E8d81a"));
    const [account, setAccount] = useState(0); // state variable to set account.
    //const [error, setError] = useState();
    const [click, setClick] = useState(false);
    //const [message1,setMessage1] = useState("");
    //const [post, setPost] = useState(null);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    var message1;
    var provider; 
    var ac1 = "";
    var isCompleted = false;
    const [data, setData] = useState({});
    if(!isSignedIn){
      
      Papa.parse("https://docs.google.com/spreadsheets/d/1itw8eIN2SycwrqrUi6Cz4773e0lFpasrGgxFcQ2uZr4/pub?output=csv", {
        download: true,
        header: true,
        complete: (results) => {
          
          isSignedIn = true;
          setData(results.data);
          WhiteList = Array.from(results.data);
          //alert("sdfsdf" + WhiteList);
          if(window.innerWidth <= 1000){
            document.getElementById("bt1_mini").style.display = 'inline-block';
            document.getElementById("bt1").style.display = 'none';
          }else{
            document.getElementById("bt1_mini").style.display = 'none';
            document.getElementById("bt1").style.display = 'inline-block';
          }
        },
      });
    }
    
    const signOut = async (e) => {
        document.getElementById('bt1').style.display = "inline";
        document.getElementById('bt2').style.display = "none";
        document.getElementById('ad1').innerHTML = "";
    }

    function test111() {
      if(!account){
        if (typeof window.ethereum != 'undefined') {
          signMessage();
        }else{
          window.location.href = 'https://metamask.app.link/dapp/www.bittoondao.com/?connect=2';
        }
      }else{
        
        var txx = "Your wallet address is <br/><span id='font_wallet'>" + account +"</span>.";
        if(numWhitelist == 0){
          txx += "<br/><br/>And you're not on the Whitelist.";
        }else if(numWhitelist == 1){
          txx += "<br/><br/>And you've got 1 whitelist.";
        }else{
          txx += "<br/><br/>And you've got " + numWhitelist + " whitelists.";
        }
        if(numNFTlist == 0){
          txx += "";
        }else if(numNFTlist == 1){
          txx += "<br/>And you've got 1 free NFT.";
        }else{
          txx += "<br/>And you've got " + numNFTlist + " free NFTs.";
        }
        //alert(txx);
        display_scroll("<div>"+txx+"</div>");
      }
    }

    async function signMessage() {
      const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
      const accounts = await web3.eth.requestAccounts();
      setAccount(accounts[0]);
    }
    
    useEffect(()=>{
      
      var pol = account.toString();
      if(pol != "0"){
        isSignedIn = true;
        var text1 = "";
        var text2 = "";
        var text3 = "";
        var ad_short = "  " + pol.substring(1,6) + "..." + pol.substring(pol.length - 4) + "  ";
        document.getElementById("bt1").innerHTML = ad_short;
        document.getElementById("bt1_mini").innerHTML = pol;
        var searchIndex = WhiteList.findIndex((car) => car.Address.toLowerCase() == pol.toLowerCase() );
        if(searchIndex != -1 ){
          
          numWhitelist = WhiteList[searchIndex].WLamount;
          numNFTlist = WhiteList[searchIndex].NFTamount;
          
          if(numWhitelist > 0){
            text1 += "You've got " + numWhitelist + " whitelists.";
            if(numNFTlist > 0) {
              text2 += "\n and \n";
            }
          }
          if(numNFTlist > 0){
            text3 += "You've got " + numNFTlist + "free NFT.";
          }
        }else{
          text1 = "You're not on the WhiteList."
        }
        //alert(text1 + text2 + text3);  
        display_scroll(text1 + text2 + text3);      
      }
      
      if(account) {
        const myTimeout = setTimeout(closeMobileMenu, 800);
      }
    }, [account]);

    const fs1 = async () => {
        await axios.get(baseURL, config)
            .then(async function(response) {
                message1 = response.data;
            })
            .catch(function(error) {
                console.log(error);
            });
        return message1;
    };

    const CloseAndSign = async (e) =>{
      //await closeMobileMenu();
      //await signMessage();
    }

    const fetchSTU = async(address) => {
      //await new Promise(r => setTimeout(r, 2000));
      //const url = "https://api.opensea.io/api/v1/assets?format=json&owner=" + '0xd3b95acb76e2176f89f1494fb2d8d846dd069ea0'+'&collection=studentofuniverse&limit=50';
      const url = "https://www.siri-art.com/bullmoonmetadata/326";
      await fetch(url)
      .then(response => response.json())
      .then((jsonData) => {
        alert(jsonData);      
               
      })
      .catch((error) => {
        //alert(error.toString());
          console.error(error)
      })
    }
    //fetchSTU();

    const closeScroll =  () => {
      document.getElementById('scrollBG_div').style.display = 'none';
    }
  return (
      <div className="header" >
        <div id='scrollBG_div' onClick={closeScroll}  >
          <div id='scrollBG_container'>
            
          </div>
        </div>
        <div className="logo-nav" >
          <div className="logo-container">
            <a href="#">
            <img src="bittoon_icon_b.png" className="logo_icon"/>
            </a>
          </div>
          <ul className={click ? "nav-options active" : "nav-options"}>
            <li className="list_menu" >
              <center><a  id="bt1_mini" className='mobile_b1' onClick={test111} >Connect Wallet and Check Whitelist</a></center>
            </li>
          </ul>
        </div>
        
        <div id='upmost_left_menu'>
            <button variant="contained" id='bt1' className="connect_button1" onClick={test111} >
              {'Connect Wallet to Check Whitelist'}
            </button>  
            <a id='wallet_address'> </a>
            <a href="https://twitter.com/BitToonDAO?s=20&t=ErEmIWoGVopJvXKsC4IS3A" target={'_blank'} >
              <img src="twitter.svg" className="upmost_svg" />
            </a>
            <a href="https://discord.gg/WAapdsywzu" target={'_blank'} >
              <img src="discord.svg" className="upmost_svg" />
            </a>
            <img src="opensea.svg" className="upmost_svg" style={{filter:'opacity(.3)'}}/>
        </div>
      
    
        <div className="mobile-menu" onClick={handleClick}>
          {click ? (
            <CloseMenu className="menu-icon" />
          ) : (
            <MenuIcon className="menu-icon" />
          )}
        </div>
      </div>
  );
}

function display_scroll(text) {
  document.getElementById('scrollBG_div').style.display = 'flex';
  document.getElementById('scrollBG_container').innerHTML = text;
  setTimeout(()=>{
    document.getElementById('scrollBG_div').style.filter = 'opacity(1)';
    //document.getElementById('scrollBG_container').style.height = '70%';
  },100);
  
}
{/* <div id='upmost_left_menu'>
          <center>
            <a id='wallet_address'> </a>
            <a href="https://twitter.com/Mazkgang?s=20&t=CD8EyvH5T4MNfb3YuQlZYQ" target={'_blank'} >
              <img src="twitter.svg" className="upmost_svg" />
            </a>
            <a href="https://discord.gg/xakkFhAzut" target={'_blank'} >
              <img src="discord.svg" className="upmost_svg" />
            </a>
            <img src="opensea.svg" className="upmost_svg" style={{filter:'opacity(.3)'}}/>
          </center>
        </div>
        <div id='upmost_right_menu'>
          <a href="" className="upmost_btn">HOME</a>
          <a onClick={() => mazkScroll('story_img1')} className="upmost_btn">JOURNEY</a>
          <a className="upmost_btn" onClick={openStoryLine} >STORY</a>
          <a onClick={() => mazkScroll('dd')} className="upmost_btn">MAZKGANG</a>
          <a onClick={() => mazkScroll('master_essentialDiv')} className="upmost_btn">SQUAD</a>
          <a onClick={() => mazkScroll('FAQ_main_container')} className="upmost_btn">FAQ</a>
        </div>
        <div id='upmost_right_menu_mini'>
          <a href="" className="upmost_btn_mini">HOME</a><br/>
          <a onClick={() => mazkScroll('story_img1')} className="upmost_btn_mini">JOURNEY</a><br/>
          <a className="upmost_btn_mini" onClick={openStoryLine} >STORY</a><br/>
          <a onClick={() => mazkScroll('dd')} className="upmost_btn_mini">MAZKGANG</a><br/>
          <a onClick={() => mazkScroll('master_essentialDiv')} className="upmost_btn_mini">SQUAD</a><br/>
          <a onClick={() => mazkScroll('FAQ_main_container')} className="upmost_btn_mini">FAQ</a>
</div> */}