import { ReactComponent as LeftStoryLine } from "../assets/left.svg";
import "../Check_Page/Check_Page.css";
import "../header/Header_2.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../redux/blockchain/blockchainActions";
import { fetchData } from "../redux/data/dataActions";
import Web3 from "web3";
import "../Mint_Page/Mint_Page.css"
import { getAirdropAmount, getProof } from "../merkle/merkleTree";
import abiData from "./abi.json";
import configJSON from "../config/config.json";
//import { id } from "ethers/lib/utils";


var isiOS = false;
var ad_short;

var isConnected = false;
var isConnectedAndWhitelisted = false;
var isAmountAvailable = false;
var isRecentlyCompleted = false;
var maxmint = 2;
var availablemint = maxmint;
var interv2_Bar;
//var contractAD = '0x619F617A3A13fefCBaeAFf9310033fd76652399C';
var contractAD = '0xCBf60AB64D521874c800627c9E1395AD0b06bd38';
function Mint_Page() {
  //document.getElementById("root").style.display = 'none'; 
  //document.getElementById("myVideo1").style.display = 'none';  
  
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [numWL , setNumWL] = useState();
  const [remainingSTR,setRemainingSTR] = useState();
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });
  
  const claimNFTs = () => {
    console.log(data.proof);
    clearInterval(interv2_Bar);
    let cost = data.cost;
    let gasLimit = CONFIG.GAS_LIMIT;

    let totalCostWei = Web3.utils.toWei((cost * mintAmount).toString());
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    document.getElementById('mintpage_mint_waiting').style.display = 'none';
    document.getElementById("mintpage_processing").style.display = "block";
    document.getElementById("mintpage_mintbtn").style.display = 'none';
    document.getElementById("mintpage_mintbtn_disabled").style.display = 'flex';
    document.getElementById('mintpage_upbtn').style.pointerEvents = 'none';
    document.getElementById('mintpage_downbtn').style.pointerEvents = 'none';
    //alert("available = " + mintAmount);
    blockchain.smartContract.methods
      [data.publicSale ? 'publicMint' : 'privateMint'](...(data.publicSale ? [] : [getProof(blockchain.account)]), ...(data.publicSale ? [] : [getAirdropAmount(blockchain.account)]) , mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log("eeeee " + err);
        setMintFail();
        //setMintComplete();

        //interv2_Bar = setInterval(readAllMinted , 10000);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        //interv2_Bar = setInterval(readAllMinted , 10000);
        //setMintFail();
        setMintComplete();

        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };
  
  const getMaxNFTs = () => {
    //alert("sdfsdfgg")
    //clearInterval(interv2_Bar);
    if(getProof(blockchain.account) <= 0){
      //(blockchain.account);
      document.getElementById('mintpage_text_whitelisted').style.display = 'none';
      document.getElementById('mintpage_text_unwhitelisted').style.display = 'flex';
    }else {
      
      blockchain.smartContract.methods.privateUserMintedAmount(blockchain.account).call()
      .then((numberMinted) => {
      setNumWL(getAirdropAmount(blockchain.account));
      //alert('check2 - numberminted' + numberMinted);
      //availablemint = maxmint - numberMinted;
      availablemint = Math.min(100, data.publicSale ? 5 : getAirdropAmount(blockchain.account) - numberMinted);
      
      if(availablemint > 0){
        //(availablemint + " " + numWL);
        if(availablemint == numWL) {
          setRemainingSTR("and you still have full whitelist.")
        } else{
          setRemainingSTR("You got " + availablemint + " whitelist(S) left." )
        }
        document.getElementById('mintpage_mint_waiting').style.display = 'flex';
        document.getElementById('mintpage_text_whitelisted').style.display = 'none';
        document.getElementById('mintpage_ready').style.fontSize = document.getElementById('mintpage_sub1').offsetWidth/12 + 'px';
        document.getElementById('mintpage_ready').innerHTML = availablemint;
        isAmountAvailable = true;
        setMintAmount(availablemint);
      } else {
        if(availablemint < 0) {
          availablemint = 0;
        }
        document.getElementById('mintpage_full').style.display = 'flex';
        document.getElementById("mintpage_mintbtn_disabled").style.display = 'flex';
        document.getElementById('mintpage_mint_waiting').style.display = 'none';
        document.getElementById('mintpage_text_whitelisted').style.display = 'none';
        document.getElementById("mintpage_mintbtn").style.display = 'none';
        document.getElementById('mintpage_upbtn').style.pointerEvents = 'none';
        document.getElementById('mintpage_downbtn').style.pointerEvents = 'none';
        document.getElementById('mintpage_ready').style.fontSize = document.getElementById('mintpage_sub1').offsetWidth/12 + 'px';
        document.getElementById('mintpage_ready').innerHTML = availablemint;
        isAmountAvailable = false;
        setMintAmount(0);
      }
    });
    }
    
  };


  const getMaxNFTs_public = () => {
    clearInterval(interv2_Bar);
    
    availablemint = 2;
    document.getElementById('mintpage_mint_waiting').style.display = 'flex';
    document.getElementById('mintpage_text_whitelisted').style.display = 'none';
    document.getElementById('mintpage_ready').style.fontSize = document.getElementById('mintpage_sub1').offsetWidth/12 + 'px';
    document.getElementById('mintpage_ready').innerHTML = availablemint;
    isAmountAvailable = true;
    setMintAmount(availablemint);
  };

  const decrementMintAmount = () => {
    if(isConnectedAndWhitelisted){
      if(isAmountAvailable){
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
          newMintAmount = 1;
        }
        document.getElementById('mintpage_ready').innerHTML = newMintAmount;
        setMintAmount(newMintAmount);
      }else{
        mintBTNCliked();
      }
    }
  };

  const incrementMintAmount = () => {
    if(isConnectedAndWhitelisted){
      if(isAmountAvailable){
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > availablemint) {
          newMintAmount = availablemint;
        }
        document.getElementById('mintpage_ready').innerHTML = newMintAmount;
        setMintAmount(newMintAmount);
      }else{
        mintBTNCliked();
      }
    }
  };

  const getData = () => {
    if(CONFIG.CONTRACT_ADDRESS){
      //readAllMinted();
    }
    resetUI();
    
    if (blockchain.account) {
      dispatch(fetchData(blockchain.account));
      var proof = getProof(blockchain.account);
      //if (proof.length > 0) {   //for whitelist
      if (true) { //for public
        ad_short = "0" +  blockchain.account.substring(1,8) + "..." + blockchain.account.substring(blockchain.account.length - 4) + "  ";
        document.getElementById('mintpage_address').style.display = 'flex';
        document.getElementById('mintpage_connectwallet_btn').style.display = 'none';
        document.getElementById('mintpage_detail_text').style.display = 'none';
        document.getElementById('mintpage_detail_header').style.display = 'none';
        document.getElementById('mintpage_checking_whitelist').style.display = 'flex';
        isConnectedAndWhitelisted = true;
        showWLSuccess();
        connectedWallet();
      } else {
        showWLFail();
      }
      isConnected = true;
    } 
  };  

  function connectedWallet() {
    
    ad_short = "0" +  blockchain.account.substring(1,8) + "..." + blockchain.account.substring(blockchain.account.length - 4) + "  ";
    document.getElementById('mintpage_address').style.display = 'flex';
    document.getElementById('mintpage_connectwallet_btn').style.display = 'none';
    document.getElementById('mintpage_detail_text').style.display = 'none';
    document.getElementById('mintpage_detail_header').style.display = 'none';
    document.getElementById('mintpage_checking_whitelist').style.display = 'flex';
    isConnectedAndWhitelisted = true;
    showWLSuccess();
    isConnected = true;
  }

  const mintBTNCliked = () => {
    if(isConnectedAndWhitelisted && !isAmountAvailable && !isRecentlyCompleted) {
      getMaxNFTs();
    }else if(isConnectedAndWhitelisted && isAmountAvailable && !isRecentlyCompleted) {
      claimNFTs();
    }else if(isConnectedAndWhitelisted && isAmountAvailable && isRecentlyCompleted) {
      isRecentlyCompleted = false;
      resetAll();
    }
  }

  const openPromotion = () => {
    document.getElementById('promo_paper_div').style.display = 'flex'

  }
  const closePromotion = () => {
    document.getElementById('promo_paper_div').style.display = 'none'

  }

  const getConfig = async () => {
    const configResponse = JSON.parse(JSON.stringify(configJSON));
    const config = configResponse;
    SET_CONFIG(config);
    getData();
  };

  useEffect(() => {
    document.getElementById('mintpage_time').style.display = 'none';
    getConfig();
    MintPage_ChangeFontSize();
    window.addEventListener('resize', MintPage_ChangeFontSize);
    if ( /(iPad|iPhone|iPod)/g.test( navigator.userAgent ) ) isiOS = true;
    return () => {
      window.removeEventListener('resize', MintPage_ChangeFontSize);
    }
  }, []);
  
  useEffect(() => {
    getData();
  }, [blockchain.account]);

  useEffect(() => {
    console.log(data);
    if(data){   
      if (data.whitelisted) {
        setFeedback('You have been whitelisted');
      } else {
        setFeedback("You aren't whitelisted");        
      }
    }
  }, [data]);

  useEffect(() => {
    //if(CONFIG.CONTRACT_ADDRESS) readAllMinted();
  },[CONFIG]);

  function setMintComplete() {
    readAllMinted();
    document.getElementById('mintpage_mintbtn').style.display = 'flex';
    document.getElementById('mintpage_mintbtn_disabled').style.display = 'none';
    document.getElementById('mintpage_success').style.display = 'block';
    document.getElementById('mintpage_mint_waiting').style.display = 'none';
    document.getElementById("mintpage_processing").style.display = "none";
    document.getElementById("mintpage_ready").style.display = "none";
    document.getElementById("mintpage_opensea").style.display = "flex";
    isRecentlyCompleted = true;
  }
  
  function setMintFail() {
    resetAll();
    document.getElementById('mintpage_fail').style.display = 'flex';
    document.getElementById('mintpage_detail_text').style.display = "none";
    document.getElementById('mintpage_detail_header').style.display = "none";
    document.getElementById('mintpage_connectwallet_btn').style.display = 'none';
    document.getElementById('mintpage_mintbtn').style.display = 'none';
    document.getElementById('mintpage_mint_waiting').style.display = 'none';
    setTimeout(()=>{
      resetAll();
    },3000);
  }
  
  function showWLSuccess() {
    document.getElementById('mintpage_checking_whitelist').style.display = 'none';
    document.getElementById("mintpage_mintbtn").style.display = 'flex';
    document.getElementById("mintpage_mintbtn_disabled").style.display = 'none';
    document.getElementById('mintpage_text_whitelisted').style.display = 'flex';
    document.getElementById('mintpage_text_unwhitelisted').style.display = 'none';
    document.getElementById('mintpage_ready').style.display = 'flex';
    document.getElementById('mintpage_upbtn').style.pointerEvents = 'auto';
    document.getElementById('mintpage_downbtn').style.pointerEvents = 'auto';
  }

  function showWLFail() {
    document.getElementById('mintpage_detail_text').style.display = 'none';
    document.getElementById('mintpage_detail_header').style.display = 'none';
    document.getElementById('mintpage_checking_whitelist').style.display = 'none';
    document.getElementById("mintpage_mintbtn").style.display = 'none';
    document.getElementById("mintpage_mintbtn_disabled").style.display = 'flex';
    document.getElementById('mintpage_text_whitelisted').style.display = 'none';
    document.getElementById('mintpage_text_unwhitelisted').style.display = 'flex';
    document.getElementById('mintpage_ready').style.display = 'none';
    document.getElementById('mintpage_upbtn').style.pointerEvents = 'none';
    document.getElementById('mintpage_downbtn').style.pointerEvents = 'none';
  }
    var diffSecond;
    var diffSecond;
    var difftime;
    var diffHour , diffHour24 ,diffMinute ,diffSecond , diffDay , mypp , isReadyToMint;
    function getCountDown(){
      const today = new Date();
      const presaleDate = new Date((1661950800)*1000); 
      //const presaleDate = new Date("August 31, 2022 17:37:40");
      //TimeZone.setDefault( TimeZone.getTimeZone("UTC"));
      //alert(presaleDate.toUTCString());
      difftime = presaleDate.getTime() - today.getTime();
      if(difftime < 1){
        difftime = 1;
        //clearInterval(interv5);
        document.getElementById('emptyd').style.display = 'none';  
        isReadyToMint = true;
        clearInterval(mypp)
      }
      diffDay = parseInt(difftime/3600/1000/24);
      diffHour = parseInt(difftime/3600/1000);
      diffMinute = Math.ceil(difftime/60/1000) - diffHour*60 - 1;
      diffSecond = parseInt(difftime/1000)%60;
      diffHour24 = parseInt(difftime/3600/1000) % 24;
      if(diffMinute < 0) diffMinute=0;
  };
  mypp = setInterval(getCountDown , 1000);


  function readAllMinted() {
    const web3 = new Web3(Web3.givenProvider || 'http://localhost:7545');
    var abi = JSON.parse(JSON.stringify(abiData));
    const contract = new web3.eth.Contract(abi , contractAD); 
    contract.methods.getTotalSupply().call().then((totalMinted)=>{
      //var tt = parseInt(totalMinted);
      var nn = 100 - parseInt(100*totalMinted / 3511);
      //alert(nn);
      document.getElementById('guage_red_img').style.clipPath = 'polygon(0 ' + nn +'%, 100% ' + nn + '%, 100% 100%, 0% 100%)';
      //var percentH = parseInt(100 - (100*(tt)/10000));
      document.getElementById('num_text').innerText = totalMinted + "/ 3511";
    });
  }
  readAllMinted();
  interv2_Bar = setInterval(readAllMinted , 15000);

  function resetUI(){
    if(document.getElementById('mintpage_detail_text')) {
      document.getElementById('mintpage_detail_text').style.display = 'flex';
      document.getElementById('mintpage_detail_header').style.display = 'flex';
      document.getElementById('mintpage_mintbtn').style.display = 'none';
      document.getElementById('mintpage_mintbtn_disabled').style.display = 'flex';
      document.getElementById('mintpage_connectwallet_btn').style.display = 'flex';
      isConnected = false;
      isConnectedAndWhitelisted = false;
      isAmountAvailable = false;
      availablemint = maxmint;
      
      document.getElementById('mintpage_text_whitelisted').style.display = 'none';
      document.getElementById('mintpage_address').style.display = 'none';
      document.getElementById('mintpage_upbtn').style.pointerEvents = 'none';
      document.getElementById('mintpage_downbtn').style.pointerEvents = 'none';
      document.getElementById('mintpage_text_whitelisted').style.display = 'none';
      document.getElementById('mintpage_text_unwhitelisted').style.display = 'none';
      document.getElementById('mintpage_full').style.display = 'none';
      document.getElementById('mintpage_mint_waiting').style.display = 'none';
      document.getElementById('mintpage_time').style.display = 'none';
      document.getElementById("mintpage_opensea").style.display = "none";
      document.getElementById('mintpage_ready').style.display = 'none';
      document.getElementById('mintpage_ready').innerHTML = 'READY!';
      var msw = document.getElementById('mintpage_sub1').offsetWidth;
      document.getElementById('mintpage_ready').style.fontSize = msw/30 + 'px';
      document.getElementById('mintpage_processing').style.display = 'none';
      document.getElementById('mintpage_success').style.display = 'none';
      document.getElementById('mintpage_fail').style.display = 'none';
    }
  }
  function resetAll() {
    readAllMinted();
    resetUI();
  }
  return (
    <div id='mintpage_maindiv'>
      <div id="emptyd" style={{width:'100vw',height:'100vh',position:'fixed',backgroundColor:'black',zIndex:'1000000001'}} >

      </div>
      <div id='checkpage_backhome' >
          {isiOS ? 
            <Link to="/" id="checkpage_backhome_text">BACK HOME</Link> 
            : 
            <div />}
          {/* <Link to="/" ><LeftStoryLine style={{display:'inline',marginBottom:'4%'}}  /></Link>&nbsp;&nbsp; */}
          
      </div> 
      <div id='mintpage_container'>
        
        <div id='mintpage_sub1'>
          <div id="promo_paper_div" onClick={closePromotion} >
             {/* <img id="promo_paper_png" src="promotion.png" /> */}
          </div>
          <div id="promotion_btn_div" onClick={openPromotion} > 
            <img src="button1.png" style={{filter:"drop-shadow(0 0 0.75rem rgba(0,0,0,.5))"}} />
          </div>
          <div id="num_text">
          </div>
          <div id="guage_mask">
            <img id="guage_mask_img" src="guage_mask.png" height="100%" />
          </div>
          <div id="guage_red">
            <img id="guage_red_img" src="guage_red.PNG" height="100%" />
          </div>
          <div id='mintpage_detail_header' style={{transform:'scale(0.8) translate(-20%,15%)'}}>
            <center>
            <br/>Please Connect Wallet <br/>
            </center>
          </div>
          <div id='mintpage_ready'>
            READY!
          </div>
          <div id='mintpage_opensea'>
            <center>
              <a target="_blank" href="https://www.opensea.io/account" >click to see your<br/>OpenSea<br/><img alt="" style={{filter:"brightness(.5)" , width:'50%',height:'auto'}}  src="opensea.svg" /></a>
            </center>
          </div>
          <div id='mintpage_checking_whitelist'>
            Moonlist Checking ...
          </div>
          <div id='mintpage_detail_text'>
            <div>
            {/*  1. กด Connect Wallet ด้านล่างเพื่อเชื่อมกระเป๋า MetaMask. <br/>
            2. ราคาชิ้นละ 0.065 ETH สำหรับรอบ Whitelist นี้<br/>
            3. กดเลือกจำนวนที่ต้องการซื้อ โดยท่านจะซื้อได้ไม่เกิน<br/>&nbsp;&nbsp;สิทธิ์ whitelist ที่ท่านมี<br/>
            4. เมื่อทำการกดมินท์หรือซื้อ ให้รอจนกระทั่ง transaction เสร็จสมบูรณ์<br/>
          5. เมื่อเสร็จแล้ว ระบบจะบอกว่ามินท์สำเร็จหรือผิดพลาด<br/><br/> */}

            </div>
          </div>
          <div id='mintpage_text_whitelisted' style={{transform:'scale(0.8) translate(-7%,0%)'}}>
            <center>
              {/* <span id="mintpage_text_whitelisted_head">ยินดีด้วย! ท่านมีรายชื่อ<br/>ใน Moon List</span><br/><br/>
              ท่านสามารถ mint ได้รวมไม่เกิน 5 ชิ้น<br/>
              ราคา 0.10 ETH ต่อชิ้น <br/>
              กรุณาเตรียม ETH ในกระเป๋าของท่านให้เพียงพอ 
            </center> */}
             <span id="mintpage_text_whitelisted_head">Welcome to BittoonDAO Whitelist</span><br/><br/>
              you can mint if you're in the whitelist.<br/>
              with special price 0.063 ETH/NFT. (from 0.085) <br/><br/>
              <hr/><br/>
              Please press "Mint" button below to start.
            </center> 
          </div>
          <div id='mintpage_mint_waiting' style={{transform:'scale(90%) translateX(-10%)' }}>
            <center>
              <br/>
              {/* <img id='mint_wait_gif' src='MINT_Waiting.gif' /> */}
              Congratulation! You have {numWL} whitelist<br/>{remainingSTR}<br/><br/>
              <hr/><br/>
              and now, you have {mintAmount} whitelist(s) left.
              <br/><span style={{fontSize:'150%',lineHeight:'100%'}}>total price {(mintAmount*0.063).toFixed(3)} ETH + GAS </span>
              <br/>(price 0.063 ETH/NFT)<br/>
            </center>
          </div>
          <div id='mintpage_text_unwhitelisted'>
            <center>
              <br/>
              <span id="mintpage_text_unwhitelisted_head">SORRY!</span><br/>
              <br/>You are not on the Whitelist<br/>
              Please come back again in the Public Sale round<br/>
              (1 September 20:00  UTC+7)
            </center>
          </div>
          <div id='mintpage_full' style={{transform:'scale(90%) translate(-40%,-30%)' }} >
            <center>
              <span id="mintpage_full_header">OOPS!</span><br/><br/>
              <div id="mintpage_full_text">
                You've already used all of your Whitelist.<br/>
                Please come bacl again in the "Public Sale" round.
              </div>
            </center>
          </div>
          <div id='mintpage_fail' style={{transform:'scale(90%) translate(-30%,-30%)' }} >
            <center>
              <span id="mintpage_fail_header">OOPS!</span><br/><br/>
              <div id="mintpage_fail_text">
                Transcantion Failed or Minting Failed<br/>
                Please Try Again.
              </div>
            </center>
          </div>
          <div id='mintpage_connectwallet_btn' onClick={(e) => {
            e.preventDefault();
            
            if (typeof window.ethereum !== 'undefined') {
              //connectMetaMask()
              dispatch(connect());
              getData();
              window.ethereum.on('accountsChanged', () => {dispatch(connect()); getData();});
            }else{
              window.location.href = 'https://metamask.app.link/dapp/bullmoon.bittoondao.com/?connect=3';
            }
          }}>
              
          </div>
          
          <div id="mintpage_address">
            <center>&nbsp;{ad_short == "" ? null : ad_short }</center>
          </div>
    
          <div id='mintpage_upbtn' onClick={incrementMintAmount} />  
          <div id='mintpage_downbtn' onClick={decrementMintAmount} />
          <div id='mintpage_mintbtn' onClick={mintBTNCliked}/> 
          <div id='mintpage_mintbtn_disabled'/>
          <div id='mintpage_time' ><center>LIVE IN<br/>72:30<br/>hours</center></div> 
          <div id='minted_amount' style={{display:'none'}} >1/5500</div>
          {/* <div id='mintpage_greenbar2'>
            <img src="greenmintbar2.gif" id='mintpage_greenbar2_img'></img>
        </div> */}
          <div id='mintpage_processing'>
            <center>
              <br/>
              <img src='processing.gif' id='mintpage_processing_img' alt="" /><br/>
              ... processing ...
            </center>
          </div>
          <div id='mintpage_success'>
            <center>
              <img src='complete.gif' id='complete' style={{width:'30%',height:'auto'}} alt=""></img><br/>
              <img src='urbullmoon.png' id='urmazk'  style={{width:'95%',height:'auto'}} alt=""></img>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
}
export default  Mint_Page;

function MintPage_ChangeFontSize() {
  var msw = document.getElementById('mintpage_sub1').offsetWidth;
  document.getElementById('mintpage_detail_text').style.fontSize = msw/50 + 'px';
  document.getElementById('mintpage_text_whitelisted').style.fontSize = msw/50 + 'px';
  document.getElementById('mintpage_detail_header').style.fontSize = msw/16 + 'px';
  document.getElementById('mintpage_address').style.fontSize = msw/42 + 'px';
  document.getElementById('mintpage_text_whitelisted').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_text_whitelisted_head').style.fontSize = msw/21 + 'px';
  document.getElementById('mintpage_text_unwhitelisted').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_text_unwhitelisted_head').style.fontSize = msw/16 + 'px';
  document.getElementById('mintpage_full').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_fail').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_full_header').style.fontSize = msw/16 + 'px';
  document.getElementById('mintpage_fail_header').style.fontSize = msw/16 + 'px';
 document.getElementById('mintpage_mint_waiting').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_time').style.fontSize = msw/36 + 'px';
  document.getElementById('mintpage_ready').style.fontSize = msw/14 + 'px';
  document.getElementById('minted_amount').style.fontSize = msw/40 + 'px';
  //document.getElementById('mint_wait_gif').style.width = msw/2.5+'px'
  document.getElementById('mintpage_processing').style.fontSize = msw/40+'px'
  document.getElementById('mintpage_opensea').style.fontSize = msw/45+'px'
  
}