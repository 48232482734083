//import SignMessage from "./SignMessage";
//import VerifyMessage from "./VerifyMessage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//mport {BrowserRouter as Router, Routes, Route , Navigate } from "react-router-dom"; 
//import RoadmapMobile from "./BTD_Roadmap_mobile";
//import Header from "./header/Header_2";
import "./App.css"
import { useState } from "react";
import MainPage from "./BTD_Roadmap";
import Mint from "./Mint_Page/Mint_Presale";
import Layout from "./Layout";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<MainPage />} />
          <Route path="mint" element={<Mint />} />  
          <Route path="*" element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
}